<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BFormFile,
  BFormGroup, BFormInput, BFormSelect, BModal, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import SampleCertificateBlank from '@/views/certificate-blank/SampleCertificateBlank.vue'
import { VueGoodTable } from 'vue-good-table'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'
import CredentialStorageSave from '@/views/credential-storage/CredentialStorageSave.vue'
import PreviewCredential from '@/views/credential-storage/PreviewCredential.vue'
import ViewStorageFile from '@/views/credential-storage/ViewStorageFile.vue'
import { Workbook } from '@syncfusion/ej2-excel-export'
import * as XLSX from 'xlsx'
import { GENDERS } from '@/const/status'
import { Flag } from '@/const/flag'

export default {
  name: 'ImportCredentialStorage',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormFile,
    ViewStorageFile,
    PreviewCredential,
    BModal,
    ValidationProvider,
    BFormInput,
    SampleCertificateBlank,
    CredentialStorageSave,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      filter: {
        credentialType: 1,
        credentialId: null,
      },
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      mainClassData: [],
      dataExcel: [],
      excelList: [],
      isLoading: false,
      columnsDegree: [
        {
          label: 'Tên sinh viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Quốc tịch',
          field: 'nationality',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Chương trình đào tạo',
          field: 'trainingSystem',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngành đào tạo',
          field: 'major',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên văn bằng',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Xếp loại',
          field: 'graduationText',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Điểm',
          field: 'score',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Lớp',
          field: 'class',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu văn bằng',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      types: 'credentialStorage/types',
      credentialsDropdown: 'dropdown/credentials',
      allGenders: 'gender/listData',
      allFactNationalities: 'curriculumVitae/allFactNationalities',
      allFactEthnics: 'curriculumVitae/allFactEthnics',
      trainingSystems: 'dropdown/trainingSystems',
      majors: 'dropdown/majors',
      schoolYears: 'dropdown/schoolYears',
      classes: 'credentialCreate/classes',
    }),

  },
  async created() {
    const cUser = getUser()
    this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
    await Promise.all([
      this.getFactNationalities({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
      this.getFactEthnics(),
      this.getMajors({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
      this.getSchoolYears(),
      this.getTrainingSystems({ currentPage: 1, itemsPerPage: 1000 }),
      this.getClasses({ currentPage: 1, itemsPerPage: 1000}),
    ])
  },
  methods: {
    GENDERS() {
      return GENDERS
    },
    ...mapActions({
      addCredentialsStorage: 'credentialCreate/addCredentialsStorage',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getFactNationalities: 'curriculumVitae/getFactNationalities',
      getFactEthnics: 'curriculumVitae/getFactEthnics',
      getMajors: 'dropdown/getMajors',
      getSchoolYears: 'dropdown/getSchoolYears',
      getClasses: 'credentialCreate/getClasses',
    }),
    async onCredentialTypeChange(event) {
      this.filter.credentialType = event
    },
    async importCredential() {
      this.isLoading = true
      try {
        const responseAdd = await this.addCredentialsStorage(this.mainClassData)
        if (responseAdd) {
          const {
            isSuccessful,
            message,
          } = responseAdd
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    async importExcel(e) {
      if (this.filter.credentialId === null) {
        this.showToast('Hãy chọn văn bằng, chứng chỉ muốn thêm vào hệ thống', 'XCircleIcon', 'danger', '')
        this.resetInputFile()
        return
      }
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },
    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsName = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsName], {
            header: 0,
            defval: '',
          })
          // Edit data
          if (ws.length > 0) {
            ws.forEach(item => {
              this.excelList.push(item)
            })
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
          // eslint-disable-next-line no-prototype-builtins
            !(this.excelList[0].hasOwnProperty('NAME')
                  && this.excelList[0].hasOwnProperty('BIRTHDAY')
                  && this.excelList[0].hasOwnProperty('ADDRESS')
                  && this.excelList[0].hasOwnProperty('GENDER_CODE')
                  && this.excelList[0].hasOwnProperty('ETHNIC_CODE')
                  && this.excelList[0].hasOwnProperty('NATIONALITY')
                  && this.excelList[0].hasOwnProperty('MAJOR_ID')
                  && this.excelList[0].hasOwnProperty('CREDENTIAL_NAME')
                  && this.excelList[0].hasOwnProperty('YEAR_ID')
                  && this.excelList[0].hasOwnProperty('GRADUATION_TEXT')
                  && this.excelList[0].hasOwnProperty('CODE')
                  && this.excelList[0].hasOwnProperty('CODE_CREDENTIAL_STORAGE')
                  && this.excelList[0].hasOwnProperty('TRAINING_SYSTEM_ID')
                  && this.excelList[0].hasOwnProperty('CLASS_ID')
                  && this.excelList[0].hasOwnProperty('SCORE')
            )
          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < this.excelList.length; k++) {
            const item = this.excelList[k]
            const obj = {
              credentialId: this.filter.credentialId,
              name: item.NAME || '',
              birthday: item.BIRTHDAY || '',
              address: item.ADDRESS || '',
              genderCode: item.GENDER_CODE || '',
              gender: this.GENDERS().find(gender => gender.value === item.GENDER_CODE).label || '',
              ethnicCode: item.ETHNIC_CODE || '',
              ethnic: item.TRAINING_SYSTEM_ID !== '' ? this.allFactEthnics.find(ethnic => ethnic.code === JSON.stringify(item.ETHNIC_CODE)).name : '',
              nationality: item.NATIONALITY || '',
              trainingSystemId: item.TRAINING_SYSTEM_ID || '',
              trainingSystem: this.trainingSystems.find(trainingSystem => trainingSystem.value === item.TRAINING_SYSTEM_ID).label || '',
              majorId: item.MAJOR_ID || '',
              major: item.MAJOR_ID !== '' ? this.majors.find(major => major.value === item.MAJOR_ID).label : '',
              credentialName: item.CREDENTIAL_NAME || '',
              yearId: item.YEAR_ID || '',
              year: item.YEAR_ID !== '' ? this.schoolYears.find(year => year.value === item.YEAR_ID).label : '',
              graduationText: item.GRADUATION_TEXT || '',
              score: item.SCORE || '',
              classId: item.CLASS_ID || '',
              class: item.CLASS_ID!== '' ? this.classes.data.find(cl => cl.value === item.CLASS_ID).label : '',
              code: item.CODE || '',
              codeCredentialStorage: item.CODE_CREDENTIAL_STORAGE || '',
              status: 1,
            }
            this.dataExcel.push(obj)
          }
          this.mainClassData = this.dataExcel
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
  },
}
</script>

<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay>
          <b-row>
            <b-col
              class="text-left"
            >
              <h3>Import văn bằng, chứng chỉ</h3>
            </b-col>
          </b-row>
          <b-row />
          <b-row>
            <b-col
              cols="3"
              class="text-left"
            >
              <b-form-group
                label="Phân loại"
                label-for="filter.credentialType"
              >
                <v-select
                  v-model="filter.credentialId"
                  :options="credentialsDropdown"
                  :reduce="option => option.value"
                  @input="onCredentialTypeChange"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              class="text-left"
            >
              <b-form-group
                label-for="file"
              >
                <template v-slot:label>
                  Chọn file văn bằng chứng chỉ: <span class="text-danger">*</span>
                </template>
                <b-form-file
                  ref="inputFileRef"
                  accept=".xls, .xlsx"
                  @change="importExcel"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="mainClassData.length > 0"
              class="text-right my-2"
            >
              <b-button
                variant="success"
                @click="importCredential"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="CheckSquareIcon" /> Import văn bằng, chứng chỉ
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <vue-good-table
                class="my-table"
                row-style-class="vgt-row"
                style-class="vgt-table striped bordered"
                :columns="columnsDegree"
                :rows="mainClassData"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>
                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<style scoped lang="scss">

</style>
