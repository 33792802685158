<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CredentialSampleConfigFileSave from '@/views/credential-sample/CredentialSampleCongfigFileSave.vue'
import SampleFileView from '@/views/credential-view-file/SampleFileView.vue'

export default {
  name: 'PreviewCredential',
  components: {
    CredentialSampleConfigFileSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    SampleFileView,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      urlCredential: '',
      currentCredentialSample: undefined
    }
  },
  computed: {
  },
  created() {
    this.urlCredential = this.item.credentialFile
    this.currentCredentialSample = {
      id: this.item.credentialSampleId,
      credentialId: this.item.credentialId,
    }
  },
}
</script>

<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
          :show="isLoading"
        >
          <SampleFileView
            :background-image="''"
            :excel-file="urlCredential"
            :current-credential="currentCredentialSample"
            :type-view="1"
          />
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<style scoped lang="scss">

</style>
