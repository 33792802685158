<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CredentialSampleConfigFileSave from '@/views/credential-sample/CredentialSampleCongfigFileSave.vue'
import SampleFileView from '@/views/credential-view-file/SampleFileView.vue'
import PdfFileView from '@/views/credential-view-file/PdfFileView.vue'

export default {
  name: 'ViewStorageFile',
  components: {
    PdfFileView,
    CredentialSampleConfigFileSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    SampleFileView,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      urlCredential: '',
    }
  },
  computed: {
  },
  created() {
    this.urlCredential = this.item.credentialFile
  },
}
</script>

<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
          :show="isLoading"
        >
          <b-row>
            <b-col>
              <h3>
                File văn bằng, chứng chỉ của sinh viên
              </h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="urlCredential && urlCredential.endsWith('.pdf')"
            >
              <PdfFileView
                :pdf-file="urlCredential"
              />
            </b-col>
            <b-col
              v-if="urlCredential && urlCredential.endsWith('.xlsx')"
            >
              <SampleFileView
                :background-image="''"
                :excel-file="urlCredential"
                :type-view="2"
              />
            </b-col>
            <b-col
              v-if="urlCredential && !urlCredential.endsWith('.pdf') && !urlCredential.endsWith('.xlsx')"
            >
              <div
                style="text-align: center; font-weight: bold"
              >
                File văn bằng, chứng chỉ của sinh viên chưa đúng định dạng!
              </div>
            </b-col>

            <b-col
              v-if="urlCredential === null || urlCredential === undefined"
            >
              <div
                style="text-align: center; font-weight: bold"
              >
                Không có thông tin về file văn bằng, chứng chỉ của sinh viên !
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<style scoped lang="scss">

</style>
